export const FirebaseConfig = {
	"projectId": "zimborides-c7ca6",
	"appId": "1:1092349794731:web:ed072068f322b2bc1d9aee",
	"databaseURL": "https://zimborides-c7ca6-default-rtdb.firebaseio.com",
	"storageBucket": "zimborides-c7ca6.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDdp4N7cYuD6XQVhlP7-AxLnLrO0MGSKXw",
	"authDomain": "zimborides-c7ca6.firebaseapp.com",
	"messagingSenderId": "1092349794731",
	"measurementId": "G-052FPS5XCD"
};